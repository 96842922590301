<template>
  <div class="container">
    <!-- 防疫查询 -->
    <div class="policySearch">
      <h5>防疫政策查询</h5>
      <div class="searchBox">
        <input type="text" v-model="target" class="" placeholder="目的地" />
        <div class="weui-btn weui-btn_primary" @click="getData">查询</div>
      </div>
    </div>
    <section class="part">
      <h5 class="title">{{ name }}</h5>
      <template v-if="policy">
        <div>
          <h5>高风险政策</h5>
          <p>{{ policy.highInDesc }}</p>
        </div>
        <div>
          <h5>低风险政策</h5>
          <p>{{ policy.lowInDesc }}</p>
        </div>
        <div>
          <h5>出行政策</h5>
          <p>{{ policy.outDesc }}</p>
        </div>
      </template>
      <p class="tips">
        以上政策整理自当地政府等公开发布的消息，如有更新或错漏，请以最新政策为准，建议在出行前咨询当地防疫部门、机场、火车站等。
      </p>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import cities from "@/lib/cities.js";
import webview from "@/mixins/webview";
import {getPolicy} from '@/api/api'
export default {
  mixins: [webview],
  data() {
    return {
      policy: null,
      target: "",
      name: "",
    };
  },
  mounted() {
    this.isWebView();
  },
  methods: {
    getCityCode(city) {
      let code = "";
      cities.cities.forEach((item) => {
        if (item.name.indexOf(city) >= 0) {
          this.name = item.name;
          code = item.code + "00";
        }
      });
      return code;
    },
    async getData() {
      let code = this.getCityCode(this.target);
    let res = await getPolicy({
      url:"https://wechat.wecity.qq.com/api/TravelPrevention/getTravelPrevent",
      params:JSON.stringify({
            args: {
            req: {
              fromCityCode: code,
              toCityCode: code,
            },
          },
          service: "TravelPrevention",
          func: "getTravelPrevent",
          context: {
            userId: "5be2e965e28c48b980d95a789c0ed0f5",
            channel: "AAGE4DTdkWHeoYS3T0Y8o3ZV",
          }
      })
    })
    console.log(res)
    this.policy =JSON.parse(res.data.data).args.rsp.from;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0.2rem;
}
.container > h5 {
  font-size: 0.16rem;
  color: #666;
  font-weight: normal;
}
.container > h5 span {
}
.part {
  background: #fff;
  padding: 0.12rem 0.18rem;
  font-size: 0.14rem;
  color: #333;
  margin-top: 0.1rem;
  border-radius: 0.1rem;
  line-height: 1.7;
}
.part>div{
  margin-bottom: 15px;
}
.part h5 {
  font-size: 0.15rem;
  margin-bottom: 0.05rem;
}
.policySearch {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding-bottom: 0.1rem;
}
.policySearch h5 {
  color: #999;
  padding: 0.07rem 0.17rem;
  font-size: 0.15rem;
  font-weight: normal;
}
.searchBox {
  position: relative;
  padding: 0 0.12rem;
}
.searchBox input {
  background: #f2f2f2;
  height: 0.4rem;
  width: 100%;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 0 0.2rem;
  font-size: 0.15rem;
  color: #666;
}
.searchBox .weui-btn {
  position: absolute;
  width: 0.6rem;
  height: 0.24rem;
  top: 50%;
  right: 0.25rem;
  transform: translateY(-50%);
  line-height: 0.24rem;
  padding: 0;
  font-size: 0.13rem;
}
.tips {
  text-align: center;
  font-size: 0.12rem;
  color: #ccc;
  margin: 0.2rem 0;
}
.title{
  font-size: 0.16rem;
}
</style>